/*@import url('https://fonts.googleapis.com/css?family=Montserrat');*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

.App {
  text-align: center;
}

/*
html {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(70% 40% at 110% 100%, rgba(209,208,198, 0.7) 0%, #FF000000 100%),radial-gradient(60% 70% at 0% 100%, rgba(255,255,254,1) 0%, #FF000000 100%),linear-gradient(270deg, rgba(210,210,203, 0.5) 0%, rgba(231,231,224, 0.5) 100%);
  font-family: 'Montserrat', system-ui, sans-serif;
}
 */

html, body, #root {
  --root-view-width: min(100vw, 60vh);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  max-width: 60vh;
  margin: auto;
  /*touch-action: none;*/
  /*font-size: calc(min(100vw, 60vh) * 0.035);*/
  /*background-color: #00623A;*/
}

/*
*, html, body{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*/

/*
body {
  background: radial-gradient(#0005ff, #86ff00);
}
*/

/*
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}
*/
